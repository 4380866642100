import { createStore } from 'vuex'

export default createStore({
  state: {
    appId: 'wx64a7b050f7894719',
    appSecret: '343969faf138b149617a5285a6fc56f1',
    accessToken: [],
    weChartDomain: 'https://open.weixin.qq.com',
    host: 'https://tt-server.fjtui.cn/',
    webHost: 'https://tt.fjtui.cn/',
    userInfo: null,
    imgHost: 'http://tt-oa.jiujintui.cn',
    mapKey: '53358ee7a59d8211006d9da41185f4de',
    mapSecurity: 'f99facf0e52c68d4d6da3e15b7d2db7b'
  },
  getters: {
  },
  mutations: {
    saveAccessToken (state, accessToken) {
      state.accessToken = accessToken
    }
  },
  actions: {
  },
  modules: {
  }
})
