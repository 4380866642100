<template>
  <div class="store-info">
    <div class="step">
    </div>

    <form class="center content" style="margin-top: 30px;">
      <span v-if="isDraft === false" style="color: #A1A1A1;">商户信息已提交，不允许修改数据</span>
      <div class="content-box basic-info" style="margin-top: 0;">
        <div class="box-title">
          <span>基本信息</span>
        </div>

        <div class="merchant-name">
          <div class="title"><span class="required">*</span>商户名称</div>
          <input type="text" maxlength="50" placeholder="请输入您的商户名称" id="merchant-name" v-if="canModify" v-model="merchantName">
          <input type="text" maxlength="50" placeholder="请输入您的商户名称" id="merchant-name" v-if="!canModify" readonly="readonly" v-model="merchantName">
          <div class="text-count">
            <span>{{merchantName.length}}</span>/50</div>
        </div>

        <div class="store-category">
          <div class="title">门店品类</div>
          <div style="color: #A1A1A1;padding-right: 10px;">美食</div>

          <div class="category-msg">门店品类将决定发布类目的范围，您在抖音发布店铺的经营类型为美食</div>
        </div>
      </div>

      <div class="content-box map">

        <div class="title"><span class="required">*</span>详细地址</div>
        <input
            class="map-search"
            type="text"
            id="keyword"
            v-model="keyword"
            @focus="clearPlaceholder"
            placeholder="请输入门店招牌地址保持一致，精确到街道门牌号"
        />

        <div class="box-title">地图位置</div>
        <div id="mapContainer"></div>
      </div>

      <div class="content-box">
        <div class="box-title">
          <span><span class="required">*</span>抖音POI门店截图</span>

          <div class="">
            <input ref="imgInput" class="img-upload-btn hidden" type="file" id="prompt-img-btn" accept="image/*">
          </div>
        </div>

        <div class="poi-screenshot">
          <img @click="triggerFileUpload(1)" v-if="poiSrc == null" src="../../../assets/merchant-info/poi-screenshot.png">
          <img @click="triggerFileUpload(1)" v-if="poiSrc != null" :src="poiSrc">
        </div>

        <div class="prompt">
          <img style="width: 14px;" src="../../../assets/merchant-info/notify.png">请上传抖音店铺截图
        </div>
      </div>

      <div class="content-box space-around">
        <div class="screenshot-title">
          <span class="required">*</span>门脸图
        </div>
        <div class="screenshot">
          <img @click="triggerFileUpload(2)" v-if="frontSrc == null" src="../../../assets/merchant-info/front.png">
          <img @click="triggerFileUpload(2)" v-if="frontSrc != null" :src="frontSrc">
          <div class="notify"><img src="../../../assets/merchant-info/notify.png">请上传门脸图</div>
        </div>
      </div>

      <div class="content-box space-around">
        <div class="screenshot-title">
          <span class="required">*</span>店内环境图
        </div>
        <div class="screenshot">
          <img @click="triggerFileUpload(3)" v-if="environmentSrc == null" src="../../../assets/merchant-info/environment.png">
          <img @click="triggerFileUpload(3)" v-if="environmentSrc != null" :src="environmentSrc">
          <div class="notify"><img src="../../../assets/merchant-info/notify.png">请上传店内环境图</div>
        </div>
      </div>
    </form>

    <button class="next-btn" @click="nextInfo">下一步</button>
  </div>
</template>

<style>
.store-info .map .map-search {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #F1F1F1;
}
.store-info .map .map-search::placeholder {
  text-align: left;
}

#mapContainer {
  width: 100%;
  height: 200px;
}

.store-info .screenshot .notify {
  color: #D40000;
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.store-info .screenshot .notify img {
  width: 14px;
}
.store-info .next-btn {
  width: 90%;
  padding: 20px;
  margin-top: 20px;
  color: white;
  background-color: #3579f6;
  border: 1px solid #3579f6;
  border-radius: 5px;
}

.store-info .space-around {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.store-info .screenshot img {
  width: 90%;
}

.store-info .screenshot {
  width: 60%;
  text-align: left;
}

.store-info .content-box .screenshot-title {
  font-weight: bold;
  width: 30%;
  text-align: left;
}

.store-info .required {
  color: #D40000;
  padding-right: 5px;
}

.store-info .prompt {
  justify-content: center;
  display: flex;
  align-items: center;
  color: #D40000;
  margin-top: 20px;
}

.store-info .poi-screenshot img {
  width: 100px;
  margin-top: 30px;
}

.store-info .category-msg {
  width: 100%;
  text-align: left;
  color: #A1A1A1;
  padding-right: 5px;
  margin-top: 10px;
  border-bottom: 1px solid #F4F4F4;
  padding-bottom: 10px;
}
.store-info .store-category {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.store-info .content input {
  margin-left: 5px;
  width: 60%;
  border: none;
  outline: none;
  height: 30px;
  font-weight: 450;
}

.store-info .content input::placeholder {
  text-align: right;
  font-weight: 200;
}

.store-info .merchant-name {
  text-align: left;
  margin-top: 20px;
  border-bottom: 1px solid #F4F4F4;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.store-info .merchant-name .text-count {
  color: #A1A1A1;
  padding-left: 10px;
  padding-right: 10px;
}

.store-info .content-box .title {
  text-align: left;
  font-weight: 450;
  width: 20%;
}

.store-info {
  overflow: hidden;
  min-height: 100vh; /* 使div的高度为视口高度 */
  width: 100vw; /* 使div的宽度为视口宽度 */
  padding-bottom: 50px;
  background-color: #f0f0f0;
}

.store-info .content .content-box {
  width: 90%;
  min-height: 100px;
  background-color: white;
  padding: 20px 10px 20px 10px;
  margin-top: 20px;
  border-radius: 5px;
}

.store-info .content .box-title {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}
</style>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import axios from "axios";

export default {
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user'));
    this.getMerchantInfo();
  },
  data() {
      return {
        province: null,
        merchantName: '',
        map: null,
        marker: null,
        keyword: '',
        city: null,
        district: null,
        street: null,
        lng: 116.397428,
        lat: 39.90923,
        provinceCode: null,
        cityCode: null,
        districtCode: null,
        poiSrc: null,
        frontSrc: null,
        environmentSrc: null,
        formData: {},
        userInfo: null,
        imgType: 0,
        mapData: null,
        canModify: false,
        isDraft: true,
        mapSetting: {
          mapKey: this.$store.state.mapKey,
          mapSecurity: this.$store.state.mapSecurity
        }
      };
    },
  mounted() {
    this.$refs.imgInput.addEventListener('change', this.uploadImage);
    this.getMapSetting();

    window._AMapSecurityConfig = {
      securityJsCode: this.$store.state.mapSecurity,
      currentMarker: null,
    };

    AMapLoader.load({
      key: this.$store.state.mapKey,
      version: '1.4.15',
      plugins: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Geocoder', 'AMap.Marker'],
    }).then((AMap) => {
      const map = new AMap.Map('mapContainer', {
        resizeEnable: true,
        center: [this.lng, this.lat],
        zoom: 13,
        keyboardEnable: false,
        zoomEnable: true,
        scrollWheel: true,
        dragEnable: true,
      });
      let currentMarker = null;

      const updateMarker = (lngLat) => {
        if (currentMarker) {
          currentMarker.setMap(null);  // 移除现有的标记
        }
        currentMarker = new AMap.Marker({
          position: lngLat,
          map: map,
        });
      };

      // 监听地图点击事件，获取点击位置的经纬度，并显示地址
      map.on('click', (e) => {
        const clickedLngLat = e.lnglat;
        map.setCenter(clickedLngLat);  // 设置地图中心为点击的地点

        const geocoder = new AMap.Geocoder();
        // 反地理编码获取地址信息
        geocoder.getAddress(clickedLngLat, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            this.keyword = result.regeocode.formattedAddress;  // 设置显示的完整地址
            // 赋值省市区信息
            const addressComponent = result.regeocode.addressComponent;
            this.province = addressComponent.province || ''; // 省
            this.city = addressComponent.city || '';         // 市
            this.district = addressComponent.district || ''; // 区
            this.street = addressComponent.street || '';     // 街道

            // 赋值省市区编码
            this.provinceCode = addressComponent.pcode || '';  // 省编码
            this.cityCode = addressComponent.citycode || '';  // 市编码
            this.districtCode = addressComponent.adcode || ''; // 区编码

            // 如果有其他字段，比如经纬度
            this.lng = clickedLngLat.lng;
            this.lat = clickedLngLat.lat;
          } else {
            console.error('反地理编码失败');
          }
        });

        // 更新标记位置
        updateMarker(clickedLngLat);
      });

      const autoOptions = {
        city: '北京',
        input: 'keyword',
      };
      const autocomplete = new AMap.Autocomplete(autoOptions);

      AMap.event.addListener(autocomplete, 'select', (e) => {
        const selectedPoi = e.poi;
        this.keyword = `${selectedPoi.address} ${selectedPoi.name}`;
        const lngLat = selectedPoi.location;
        map.setCenter(lngLat);
        updateMarker(lngLat);
        this.province = selectedPoi.pname || '';  // 省
        this.city = selectedPoi.cityname || '';   // 市
        this.district = selectedPoi.adname || ''; // 区
        this.street = selectedPoi.address || '';  // 街道
        // 赋值省市区编码
        this.provinceCode = selectedPoi.pcode || '';  // 省编码
        this.cityCode = selectedPoi.citycode || '';  // 市编码
        this.districtCode = selectedPoi.adcode || ''; // 区编码
        // 如果需要经纬度等其他字段
        this.lng = lngLat.lng;
        this.lat = lngLat.lat;
      });

    }).catch((e) => {
      alert('高德地图加载失败:' + e);
    });
  },
  methods: {
    async getMapSetting() {
      await axios.get(this.$store.state.host + 'tt/wechat/common/map/setting?type=map').then(response => {
        this.mapSetting.mapKey = response.data.body.appKey
        this.mapSetting.mapSecurity = response.data.body.appSecret
      });
    },
    nextInfo() {
      if (this.canModify === false) {
        this.$router.push({name: 'merchantQualificationInfo'})
        return;
      }

      if (this.merchantName === '') {
        alert('请输入商户名称')
      }

      if (this.poiSrc === null) {
        alert('请上传POI门店图')
        return;
      }

      if (this.frontSrc === null) {
        alert('请上传门脸图')
        return;
      }

      if (this.environmentSrc === null) {
        alert('请上传店内环境图')
        return;
      }

      if (this.province === null) {
        alert('请选择地图位置')
        return;
      }

      let storeLocJson = {
        province: this.province,
        city: this.city,
        district: this.district,
        street: this.street,
        provinceCode: this.provinceCode,
        cityCode: this.cityCode,
        districtCode: this.districtCode
      }

      let formData = {
        uid: this.userInfo.id,
        lng: this.lng,
        storeLocJson: JSON.stringify(storeLocJson),
        lat: this.lat,
        mname: this.merchantName,
        addr: this.keyword,
        poiImage: 1,
        sfImage: 2,
        inImage: 3,
      }

      axios.post(this.$store.state.host + 'tt/wechat/merchant/onboarding/store', formData
      ).then(response => {
        this.$router.push({name: 'merchantQualificationInfo'})
      }).catch(function (error) {
        alert(error.body.msg)
      });

    },
    clearPlaceholder() {
      this.keyword = '';
    },
    triggerFileUpload(type) {
      if (this.canModify) {
        this.imgType = type
        this.$refs.imgInput.click();
      }
    },
    uploadImage(event) {
      const file = event.target.files[0];
      if (!file) {
        console.log('No file selected');
        return;
      }
      var fileSize = file.size; // 获取文件大小（字节）
      var maxSize = 5 * 1024 * 1024; // 设置最大文件大小（这里设置为1MB）
      if (fileSize > maxSize) {
        alert('图片大小不能超过5MB！');
        return false;
      }

      axios.post(this.$store.state.host + 'tt/wechat/common/merchant/img', {
        file: file,
        type: this.imgType,
        uid: this.userInfo.id
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        alert('上传成功！')
        if (this.imgType === 1) {
          this.poiSrc = response.data.body.url;
        } else if (this.imgType === 2) {
          this.frontSrc = response.data.body.url;
        } else if (this.imgType === 3) {
          this.environmentSrc = response.data.body.url;
        }
      }).catch(function (error) {
        alert('上传失败图片失败！')
      });
    },
    getMerchantInfo() {
      axios.get(this.$store.state.host + 'tt/wechat/merchant/info', {
        params: {
          userId: this.userInfo.id
        }
      }).then(response => {
        let merchantInfo = response.data.body;

        if (merchantInfo.storeCmd != null) {
          this.merchantName = merchantInfo.storeCmd.mname;
          this.keyword = merchantInfo.storeCmd.addr;

          this.poiSrc = merchantInfo.storeCmd.poiImage;
          this.frontSrc = merchantInfo.storeCmd.sfImage;
          this.environmentSrc = merchantInfo.storeCmd.inImage;
        }

        if (merchantInfo.storeLocJson != null) {
          let storeLocJson = JSON.parse(merchantInfo.storeLocJson)

          this.province = storeLocJson.province;
          this.city = storeLocJson.city;
          this.cityCode = storeLocJson.cityCode;
          this.district = storeLocJson.district;
          this.street = storeLocJson.street;
          this.provinceCode = storeLocJson.provinceCode;
          this.districtCode = storeLocJson.districtCode;
        }

        if (merchantInfo.storeCmd.lat !== '') {
          this.lat = merchantInfo.storeCmd.lat;
        }
        if (merchantInfo.storeCmd.lng !== '') {
          this.lng = merchantInfo.storeCmd.lng;
        }

        if (merchantInfo.status !== 4) {
          this.canModify = true;
        } else {
          this.isDraft = false
        }

      }).catch(function (error) {
        alert('获取用户信息失败！')
      });
    }
  },
  beforeUnmount() {
    this.$refs.imgInput.removeEventListener('change', this.uploadImage);
  }
}
</script>